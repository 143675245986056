/*
 =========================================================
 * Vue Black Dashboard Laravel - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-laravel
 * Copyright Creative Tim (http://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)

 
 * Coded by www.creative-tim.com & www.updivision.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import VueGoodTablePlugin from 'vue-good-table'
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import store from "./store";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
import VueMeta from 'vue-meta'
import IsDemo from './plugins/isDemo'
import { BPaginationNav } from 'bootstrap-vue'
import { BPagination } from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BImg } from 'bootstrap-vue'
import { BImgLazy } from 'bootstrap-vue'
import { BBadge } from 'bootstrap-vue'


import { BFormGroup } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
import { InputGroupPlugin } from 'bootstrap-vue'
import { BCard } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { LayoutPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { ProgressPlugin } from 'bootstrap-vue'
import { AlertPlugin } from 'bootstrap-vue'




Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueGoodTablePlugin);
Vue.use(IsDemo)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ProgressPlugin)

Vue.use(AlertPlugin)
Vue.component('b-pagination-nav', BPaginationNav)
Vue.component('b-pagination', BPagination)
Vue.component('b-table', BTable)
Vue.component('b-button', BButton)
Vue.component('b-img', BImg)
Vue.component('b-img-lazy', BImgLazy)
Vue.component('b-badge', BBadge)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-card', BCard)
Vue.component('b-form', BForm)
Vue.use(VueMeta, { keyName: 'head' })

/* eslint-disable no-new */
new Vue({
  store: store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
